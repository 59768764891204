import { Toast } from "vant";

export const mixins = {
  data() {
    return {
      urlList: [
        {
          url: "https://出现屏蔽替换此处.fj6rz.com/user.html?dc=DYDL",
          icon: require("./../assets/png/logo-haijiao.png"),
          name: "海角乱伦",
          time: 0,
        },
        {
          url: "https://出现屏蔽替换此处.e5mtd.com/user.html?dc=DYDL",
          icon: require("./../assets/png/logo-nut.png"),
          name: "NUT",
          time: 0,
        },
        {
          url: "https://出现屏蔽替换此处.ub3t6.com/user.html?dc=DYDL",
          icon: require("./../assets/png/logo-haose.png"),
          name: "好色先生",
          time: 0,
        },
        {
          url: "https://出现屏蔽替换此处.saxzg.com/user.html?dc=DYDL",
          icon: require("./../assets/png/logo-anwang.png"),
          name: "91暗网",
          time: 0,
        },
        {
          url: "https://1l3sp.vip/user.html?dc=DYDL",
          icon: require("./../assets/png/logo-smtt.png"),
          name: "涩漫天堂",
          time: 0,
        },
        {
          url: "https://出现屏蔽替换此处.4ixx1.com/user.html?dc=DYDL",
          icon: require("./../assets/png/logo-tiktok.png"),
          name: "TikTok",
          time: 0,
        },
        {
          url: "https://出现屏蔽替换此处.4jf5x.com/user.html?dc=DYDL",
          icon: require("./../assets/png/logo-hls.png"),
          name: "黑料社",
          time: 0,
        },
        {
          url: "https://出现屏蔽替换此处.r3ncm.com/user.html?dc=DYDL",
          icon: require("./../assets/png/logo-lieqi.png"),
          name: "91猎奇",
          time: 0,
        },
        {
          url: "https://出现屏蔽替换此处.zxa2b.com/user.html?dc=DYDL",
          icon: require("./../assets/png/logo-qiyou.png"),
          name: "妻友社区",
          time: 0,
        },
      ], // 域名数组
      ping: 1,
      timer: null,
      showImg: true,
      addressUrl: "cao.gg",
    };
  },
  mounted() {
    this.addressUrl = window.location.host;
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      this.timer = setInterval(() => {
        this.ping++;
      }, 100);
    },
    autoTest(item, index) {
      if (this.ping <= 10) {
        item.time = this.ping * 10;
      } else {
        item.time = this.ping * 100;
      }
      if (index == this.urlList.length - 1) {
        clearInterval(this.timer);
      }
    },
    jumpUrl(url) {
      window.open(url);
    },
    reset() {
      this.urlList = this.urlList.map((item) => {
        item.time = 0;
        return item;
      });
      this.showImg = false;
      this.$nextTick(() => {
        this.showImg = true;
      });
      this.ping = 1;
      this.init();
    },
    doCopy() {
      let req = this.addressUrl;
      this.$copyText(req).then(
        () => {
          Toast("复制成功");
        },
        () => {
          Toast("复制失败");
        }
      );
    },
  },
};
